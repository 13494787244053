<template>
    <div class="pageheader" :style="'background-image: '+ 'url('+background+');background-position:'+position+';'">
        <div class="header-text">
            <h1>{{ title }}</h1>
            <h3>{{ subtitle }}</h3>
        </div>
    </div>
</template>


<script>
export default {
    props:{
        title:{
            type: String
            
        },
        subtitle:{
            type:String
        },
        background:{
        },
        position: {
            default: 'center'
        }
    }
}
</script>


<style scoped>
.pageheader{
    position: relative;
    height: 80vh;
    /* background: url('https://images.unsplash.com/photo-1487349703519-90c8e4f426a7?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=994&q=80'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #a7a7a7;    
}

.header-text{
    position: absolute;
    left: 30%;
    top: 50%;
    transform: translate(-50%,-50%);
    padding:20px;
}

h1{
    font-size: 90px;
    margin: 0;
    color: white;
    font-weight: 700;
    font-family: var(--font-family-2);
    letter-spacing: 5px;
}
h3{
    font-size: 24px;
    color: white;
    margin: 0;
    font-family: var(--font-family-1);
    font-weight: lighter;
    margin-left: 10px;
}


@media(max-width:700px){
    .pageheader {
        height: 480px;
    }

    .header-text{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
    h1{
        font-size: 42px;
        letter-spacing: 2px;
        line-height: 60px;
        text-align: center;
    }

    h3{
        font-size: 20px;        
        text-align: center;
        margin-left: 0px;
    }
}
</style>