<template>
    <div class="contact">
        <PageHeader title="Booking" subtitle="Send us a booking enquiry." background="https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FMain%20Gallery%20replacement.jpg?alt=media&token=deb2ed07-db54-49bc-ab23-1843e9ba0845"/>
        <div class="py-50 bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h2>{{ $route.params.id }}</h2>
                        <p>Please enter your details</p>
                    </div>
                </div>
                <div class="row">                    
                    <div class="col-md-6 offset-md-3">
                        <div class="p-5 form-wrapper">
                           <contact-form />
                        </div>
                    </div>
                </div>
             
            </div>
        </div>
    </div>
</template>

<script>
import ContactForm from '../components/ContactForm.vue'
import PageHeader from '../components/PageHeader'

export default {
    components:{
        PageHeader,
        ContactForm        
    },
}        
        
</script>


<style scoped>
.bg{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.527),rgba(255, 255, 255, 0.527)) ,url('./../assets/svg/bg.svg') repeat;
}

.form-wrapper{
    background: white;
}

h2{
    color: var(--primary);
}
.contact-details p{
    font-size: 26px;
    color: #777;
}

@media(max-width:700px) {
    .contact h2 {
        font-size: 28px;
    }

    .contact p {
        font-size: 16px;
    }
}

</style>