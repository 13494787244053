<template>
<div>
    <div class="hero">        
            <div class="text-center">
                <h1 class="m-0">DREAM. EXPLORE. DISCOVER</h1>
                <div style="margin-bottom:-50px">
                    <div style="margin-top:50px;">
                        <a id="getStartedBtn" @click.prevent="gotToExperiences">GET STARTED</a>
                    </div>
                </div>
            </div>

            <div class="video-container">
                <!-- <video ref="myVideo" preload="auto" autoplay loop  width="100%" src="https://github.com/ayanbora1994/vegastep-storage/raw/bfe52df81925d5a11f0ace8d7827a0b0680c3861/stayhigh_hero_bg_video.mp4" type="video/mp4"></video>             -->
                <video ref="myVideo" autoplay loop >
                    <source src="https://storage.googleapis.com/stayhighadventures/stayhigh_hero_bg_video.mp4" type="video/mp4">
                </video>
            </div>
            <div class="overlay"></div>
            <div class="sound-control">
                <a @click="onMuted()" >
                    <img v-if="muted" src="../assets/svg/no-sound.svg" width="30px" alt="">
                    <img v-else src="../assets/svg/sound.svg" width="30px" alt="">
                </a>                       
            </div>
        </div>        
</div>    
</template>


<script>

export default {    
    props: ['experiencesYPos'],
    data() {
        return {                  
            muted: true
        }
    },
    methods:{
        onMuted() {
            this.$refs.myVideo.muted = !this.$refs.myVideo.muted
            this.muted = !this.muted
        },
        gotToExperiences() {
            window.scrollTo(0, this.experiencesYPos);                              
        }
    },
    mounted() {
        var video = this.$refs.myVideo;
        var promise = video.play();

        if (promise !== undefined) {
        promise.then( _ => {
            this.muted = false
        }).catch(error => {            
            video.muted = true;
            video.play();  
            this.muted = true       
        });
        }        
    }
}
</script>

<style scoped>

/* .social-icons-wrapper {
    position: absolute;
    height:100vh;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    top:0;
    padding-left: 25px;;
}

/* .social-icons {
    position: absolute;
    bottom: 150px;
    left:25px;
} */

ul {
    padding-left: 0;
}

ul li{
    list-style: none;
    margin-bottom:30px;
}


ul li:hover{
    color: #FF6600;
    cursor: pointer;
}

.hero{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    
}


/* .social-icons-wrapper{
    position: absolute;
    top: 0;
    left: 10px;
} */

.sound-control{
    position: absolute;
    left: 20px;
    bottom: 20px;
    cursor: pointer;
}

/* .hero > div{
    height: auto;
} */


.hero h1{
    font-size: 42px;
    font-weight: 400;
    text-shadow: 0px 0px 4px rgba(150, 150, 150, 0.664);
    font-family: var(--font-family-2);
    
    letter-spacing: 10px;
}

.hero p{
    font-size: 20px;
    width: 80%;
    margin: 20px auto;
    line-height: 33px;
}

.hero a{
    text-decoration: none;
    font-weight: bold;
    color: white;
    letter-spacing: 4px;
    font-size: 20px;
}


.hero a:hover{
    /* color: #FF6600; */
}

.video-container{
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
    /* background-color: #6f6f6f; */
    z-index: -1;
}

.video-container video{
    height: 100vh;
    width: 100%;
    object-fit: cover;
}


.overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #2e2e2e93 100%), linear-gradient(0deg, rgba(224, 186, 47, 0.116), rgba(224, 185, 47, 0.29)), #c4c4c41f; */
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.33) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.33) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: -1;
}

.hero-content {
    line-height: 35px;
}

#getStartedBtn {
    font-family: var(--font-family-2);
    border:1px solid white;
    padding:10px 20px;
    background:transparent;
    cursor: pointer;
}

#getStartedBtn:hover{
    background: rgba(255, 255, 255, 0.171);
}


@media(max-width:700px){
.hero{
    height: 480px !important; 
}

.sound-control {
    bottom: unset;
    top: 430px;
    left: 15px;
    /* bottom: 270px; */
    /* left: 10px; */
}

.sound-control img{
    border-radius: 50%;
}

.video-container video{
    height: 480px;
}

.video-container{
    height: 480px;
   
}

.hero h1{
    font-size: 26px;
    text-shadow: 0px 0px 4px rgba(150, 150, 150, 0.664);        
}


.overlay{
    height: 480px !important;
}
.hero p{
    font-size: 20px;
    width: 80%;
    margin: 10px auto;
    line-height: 33px;
}

#getStartedBtn {
    font-size: 18px;
}

}
</style>