<template>
  <div id="app">   
    <mobile-navigation v-if="$mq == 'sm'"></mobile-navigation>
    <Navigation v-else />
    <router-view />
    <a href="https://wa.me/+919395699623" target="_blank" class="whatsapp-chat-btn"><whatsapp fillColor="#25D366" /><span>
    {{ $mq == 'sm'? '' : 'WhatsApp Us' }}  
    </span> </a>
  </div>
</template>


<script>
import Navigation from './components/Navigation'
import MobileNavigation from './components/MobileNavigation'
import Whatsapp from 'vue-material-design-icons/Whatsapp.vue'

export default {
  components:{
    Navigation,
    MobileNavigation,
    Whatsapp
  },
   created(){
    window.addEventListener('contextmenu', (e) => {
      if(e.target.nodeName === 'IMG'){
        // alert('Dont save the pic please :(')
        e.preventDefault();
      }
        return false;
    })
  },
  destroyed(){
    console.log('destroy')
     window.removeEventListener('contextmenu', (e) => {
      if(e.target.nodeName === 'IMG'){
        e.preventDefault();
      }
        return false;
    })
  }
}
</script>

<style scoped>
.whatsapp-chat-btn {
  position: fixed;
  display: flex;  
  align-items: center;
  bottom:20px;
  right:20px;
  padding:10px 20px;
  background: white;
  color: #25D366;
  border-radius: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);  
  text-decoration: none;
}

.whatsapp-chat-btn>span {
  margin-left:5px;
  line-height: 100%;
}

.cool-lightbox .cool-lightbox__slide img{
  margin: 0 !important;
}

</style>
