<template>
  <div class="home">    
    <Hero :experiencesYPos="experiencesYPos" />
    <Introduction />    
    <Experiences @ready="onComponentReady($event)" />
  </div>
</template>

<script>
import Experiences from "@/components/Experiences.vue"
import Hero from '@/components/Hero.vue'
import Introduction from '@/components/Introduction.vue'

export default {
  data: () => ({
    loaded: false,
    experiencesYPos: 0
  }),  
  methods: {
    onComponentReady(val) {
      this.loaded = true
      this.$nextTick(() => {
        this.experiencesYPos = val.experienceCardPos
      })
    },
    setExperiencesYPos(val) {
      this.experiencesYPos = val
    }
  },
  components:{
    Introduction,
    Experiences,
    Hero  
  }
}

</script>