<template>
  <div class="gallery">
    <PageHeader title="Gallery" subtitle="Glance into our world"
      background="https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_0604.jpg?alt=media&token=f0b653f2-3597-4836-b6a9-5a692d597d3a"
      position="right" />
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <CoolLightBox :items="items" :index="index" @close="index = null">
          </CoolLightBox>
        </div>
      </div>
    </div>

    <div class=" py-80 px-50 bg">
      <div class="d-flex flex-wrap">
        <div class=" mx-1 mb-2 image flex-fill" v-for="(image, imageIndex) in items" :key="imageIndex"
          @click="index = imageIndex" :style="'background-image: ' + 'url(' + image + ')'"></div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
export default {
  data() {
    return {
      items: [
        // "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_0036.jpg?alt=media&token=7574cf66-c81d-4b25-baea-42d5ed80360e",
        // "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_0051.jpg?alt=media&token=4538b65d-6d79-42e6-b115-dcb0d72764af",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_0149.jpg?alt=media&token=20c781be-5cee-4473-8ca5-d2dfaaa3abda",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_0282.jpg?alt=media&token=a833a97d-ca52-4076-a10d-32a04de5609a",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_0295.jpg?alt=media&token=3904c133-f04c-46f2-a6a0-e2c4c78bbcad",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FMain%20gallery%20replacements.jpg?alt=media&token=53069768-5702-4a17-adf0-a34ac6e73611",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_0479.jpg?alt=media&token=eba89adc-bde9-4a52-90dd-98f609d2603c",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FMain%20Gallery%20replacement.jpg?alt=media&token=deb2ed07-db54-49bc-ab23-1843e9ba0845",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_1235.jpg?alt=media&token=91826a6e-9b77-40c1-ac0f-8aefaf4672f9",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_1416.jpg?alt=media&token=aa4cfcc7-7b53-4b1d-9410-5e089c66a332",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_1490.jpg?alt=media&token=45918c40-1f64-40d6-8ce1-42b13eb89af4",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_1529.jpg?alt=media&token=54101c1f-8a9b-4c0e-abaa-a458d5e5345e",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_5165.jpg?alt=media&token=963d116e-b166-4387-9f51-8e5236b7c52b",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_5281.jpg?alt=media&token=5e7e9e72-8742-4558-9843-60cfe7e1f12a",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_5808.jpg?alt=media&token=d908b260-50c1-4733-a242-05cfde98b324",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_8498.jpg?alt=media&token=be3d49a1-fc34-4b37-aa6a-b42683ac15c1",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_8501.jpg?alt=media&token=3dbe7dfb-e212-4de7-b782-f293f23b8519",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_8569.jpg?alt=media&token=f2b770d0-976c-4f4e-a7c0-a81149f636c5",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_8579.jpg?alt=media&token=7939b587-525b-4ca2-bc9d-cee729624809",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_9755.jpg?alt=media&token=a78895c9-5fc0-43c8-99fb-7d897da05ad5",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FIMG_8693.jpg?alt=media&token=4cb042b6-4b8b-48e4-b250-c13fce91aa9c",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FIMG_8763.jpg?alt=media&token=269621c7-01e9-4a07-b13a-7fa6d53a6f8f",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FIMG_8820.jpg?alt=media&token=82ec139f-ad90-4758-b453-171793e09929",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/tusker%2FDSC_2161.jpg?alt=media&token=5e14c502-0359-4257-8147-edd36bab7346",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/tusker%2FDSC_2188.jpg?alt=media&token=6317f915-d688-4b6a-a4b2-92d1c49878d3",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/tusker%2FDSC_2213.jpg?alt=media&token=9815a7a8-087b-48b8-80a4-108ab1b7bb8d",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/tusker%2FDSC_2539.jpg?alt=media&token=e47244b4-25ec-4079-bd85-0f2304a28a1d",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/tusker%2FDSC_2186.jpg?alt=media&token=1c3dd66c-cb23-4eca-a2d8-981541d1495a",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/tusker%2FDSC_2322.jpg?alt=media&token=dd31926b-3752-4bbd-8df7-435d0d4b23e2",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/ziro%2F10.JPEG?alt=media&token=eefb5925-81b4-471f-b213-1e76ce9037d0",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/ziro%2F11.JPG?alt=media&token=5d144e05-cb33-45c4-ac40-4a68d8cccf79",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/ziro%2F13.JPG?alt=media&token=3e6caf1d-e832-406e-a64a-329cc2154f26",
        "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/ziro%2F16.JPG?alt=media&token=97e3a329-e42c-4efe-b446-bcbd2da545ae"
      ],
      index: null,
    };
  },
  components: {
    PageHeader,
    CoolLightBox,
  },


};
</script>

<style scoped>
.bg {
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.527),
      rgba(255, 255, 255, 0.527)),
    url("./../assets/svg/bg.svg") repeat;
}

.image {
  height: 300px;
  width: 350px;
  background-size: cover;
  background-position: center;
}
</style>
