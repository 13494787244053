<template>
  <div ref="experiencesList" id="experiences">    
    <div class="experience-heading">
      <div class="text-center">
        <img src="@/assets/logo-label.png" width="100" alt="">
      </div>
      <h2 class="m-0 text-center mb-5">Experiences</h2>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <Slider @change="handleNext($event)" @closeNext="goNext = false" :goNext="goNext" :options="flickityOptions">
              <!-- <div class="carousel-cell" @click="currentTabId = 1">                        
                <div class="experience-card alt d-flex flex-column justify-content-between">
                  <div class="d-flex justify-content-between">
                    <span>Assam</span>
                    <span style="letter-spacing:2px;">2D. 1N</span>
                  </div>
                  <div>
                    <h3 class="mb-3 title">heartland assam</h3>
                    <div>
                      <span class="mr-2 label" style="font-size:16px">Wildlife</span>
                      <span class="label" style="font-size:16px">Bonfire</span>
                    </div>
                  </div>
                  <div class="card-price">₹ 3000</div>
                </div>                                       
              </div>               -->
              <div class="carousel-cell" v-for="(experience, i) in experiences" :key="`index-${i}`" @click="setCurrentTab(i)">                        
                <div class="experience-card d-flex flex-column justify-content-between" :style="'background-image: '+ 'url('+experience.background+')'" :class="[ currentTabId == i? 'active': '']">
                  <div class="d-flex justify-content-between">
                    <span>{{ experience.location }}</span>
                    <span style="letter-spacing:2px;">{{ experience.duration }}</span>
                  </div>
                  <div>
                    <h3 class="mb-3 title">{{ experience.name }}</h3>
                    <div>
                      <span class="mr-2 label" style="font-size:12px" v-for="tag in experience.tags" :key="tag">{{ tag }}</span>
                      
                    </div>
                  </div>
                  <div class="card-price">
                    <span class="mr-2" style="font-weight:400;letter-spacing:1px;font-size:18px;">Starts</span><span style="font-size:24px;"><span style="font-family:sans-serif;font-size:24px;">₹ </span>{{ experience.cost }}/-</span>
                  </div>
                </div>                                       
              </div>
            </Slider>  
          </div>
        </div>
      </div>

      <!-- <div id="experience-tabs" class="text-center d-flex">
        <div v-for="i in 5" :key="i" class="experience-card d-flex flex-column justify-content-between">
          <div class="d-flex justify-content-between">
            <span>Meghalaya</span>
            <span>21.nv</span>
          </div>
          <div class="d-flex justify-content-center">
            <h3>LEAP OF LIKAI</h3>
          </div>
          <div class="card-price">3000</div>
        </div> -->
        <!-- <button @click="currentTabId = 1">Experience 1</button>
        <button @click="currentTabId = 2">Experience 2</button> -->
      <!-- </div>     -->
    </div>

    <!-- <fade-transition group> -->
      <div class="experience-content" style="margin-top:-20px">
        <template v-for="(experience,index) in experiences">
          <div :key="`tab-${index}`" v-show="currentTabId == index">
            <transition name="slide-fade" mode="out-in">
              <ExperiencesItem @next="handleNext" :key="currentTabId" :experiencesCardPos="experiencesYPosVal" :name="experience.name" :shots="experience.shots" :placeStats="experience.stats"  :nextLoc="getNextLoc(index)" :placeItenarary="experience.itenarary" :placeInclusions="experience.packageInclusions" :aboutPlace="experience.about" :isEvent="experience.isEvent" :placeId="experience.id"/>
            </transition>
          </div>
        </template>
      </div>
    <!-- </fade-transition> -->
  </div>
</template>

<script>
// import { FadeTransition } from 'vue2-transitions'
import ExperiencesItem from '@/components/ExperiencesItem'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    currentTabId: 0,  
    experiencesCardPos: 0,
    experiencesYPosVal: 0,
    // flickityOptions: {
    //   initialIndex: 1,
    //   // contain: true,        
    //   // prevNextButtons: false,
    //   // adaptiveHeigth: true,      
    //   pageDots: false,      
    //   // wrapAround: false
    // },           
    goNext: false
  }),
  mounted() {    
    this.$emit('ready', { experienceCardPos: this.experiencesYPos() })
    this.experiencesYPosVal = this.experiencesYPos()
  },
  computed: {
  ...mapGetters(['experiences']),
    flickityOptions() {
      let obj = {
        initialIndex: 1,
        // contain: true,        
        prevNextButtons: true,
        // adaptiveHeigth: true,      
        pageDots: false,      
        // wrapAround: false        
      }
      // if(this.$mq == 'sm') { obj.prevNextButtons = false }
      return obj
    },
  },
  methods: {
    handleNext(index) {            
      if(!isNaN(index)) {
        this.currentTabId = index  
      } else {
        this.currentTabId = this.currentTabId == this.experiences.length - 1? 0 : this.currentTabId + 1      
        this.goNext = true
      }
    },
    getNextLoc(index) {
      return index == this.experiences.length - 1? this.experiences[0].name : this.experiences[index+1].name
    },
    setCurrentTab(i) {
      this.currentTabId = i
    },
    experiencesYPos() {
      let rect = !!this.$refs.experiencesList? this.$refs.experiencesList.getBoundingClientRect() : { top: 0 }
      return rect.top + window.scrollY + 80
    }    
  },
  watch: {
    experiencesYPos(val) {
      if(val > 0) {
        console.log('boo')
      }
    }
  },
  components: {
    // FadeTransition,
    ExperiencesItem
  }
}
</script>


<style scoped>

.slide-fade-enter-active {
  transition: all .8s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

.experience-heading{  
  padding-top:60px;
  height: 600px;
  /* background: linear-gradient(180deg, #F8E5D4 0%, rgba(107, 95, 84, 0.59) 74.48%, #FFFFFF 100%), url("https://images.pexels.com/photos/355241/pexels-photo-355241.jpeg?cs=srgb&dl=pexels-pixabay-355241.jpg&fm=jpg"); */
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.38) 52.08%, #FFFFFF 100%), url("../assets/front-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


.label{
  border: 1px solid white;
  padding: 1px 10px;
  border-radius: 50px;
  
}

h2{
  color: var(--primary);
  font-family: var(--font-family-2);
}


.experience-card{
  height: 100%;
  width: 100%;
  /* border: 10px solid white; */
  /* background: url('./../assets/heartland-assam.jpg'); */
  background-color: #2b2b2b;  
  background: linear-gradient(0deg, rgba(49, 48, 48, 0.23), rgba(49, 48, 48, 0.23)), url('https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/likai%2FIMG_8693.jpg?alt=media&token=f6954401-062c-4083-bd85-dbb3e6b790bd');
  background-size: cover;
  background-position: center center;
  padding: 20px;
  border-radius: 4px;
  color: white;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  z-index: 0;
}


/* .experience-card:hover{
  transform: scale(1.1);
} */


.experience-card::before{
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.219), rgba(0, 0, 0, 0.048));
  z-index: -1;
}


.experience-card h3{
  z-index: 1;
}



.alt {      
  background: linear-gradient(0deg, rgba(49, 48, 48, 0.23), rgba(49, 48, 48, 0.23)), url('https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/heartland%2FDSC_1285.jpg?alt=media&token=88812de5-f096-483a-9517-2125192ab1ed');
  background-size: cover;
  background-position: center center;
  
}

.alt-2 {
  background: linear-gradient(0deg, rgba(49, 48, 48, 0.23), rgba(49, 48, 48, 0.23)), url('https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/dzukou%2FDSC_8496.jpg?alt=media&token=1e0b8b24-c79c-488e-bcb2-4be4557ffd50');
  background-size: cover;
  background-position: center center;
}
.alt-3 {
  background: linear-gradient(0deg, rgba(49, 48, 48, 0.23), rgba(49, 48, 48, 0.23)), url('https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/mohian%2FIMG_8183.jpg?alt=media&token=7073f73d-49a0-4c4d-8a3f-907598d1d690');
  background-size: cover;
  background-position: center center;
}

.active {
  /* border-bottom: 5px solid #ff6600; */
}


.active::before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 10px solid #ff6600;
  /* background: linear-gradient(0deg, rgba(255, 102, 0, 0.43) 60%, rgba(255, 102, 0, 0.034)); */
  transition: all 0.2s ease-in-out;
}

.experience-card span{
  font-size: 20px;
}

.experience-card h3{
  font-size: 28px;
  font-family: var(--font-family-2);
}

.experience-card .card-price{  
  display: flex;
  justify-content: flex-end;
  font-family: var(--font-family-2);
  font-weight: 600;
  align-items: center;
}

/* .experience-card:first-child{
  margin-left: 20px;
}
.experience-card:not(last-child){
  margin-right: 20px;
} */

.carousel-cell {
  width:300px;
  height: 400px;
  /* background: red; */
  padding-top:30px;
  padding-bottom: 30px;
  margin-right: 10px;
  background: transparent;
  overflow: hidden;
  /* border-radius: 50%; */
  transition: all 0.3s ease-in-out;
}

.carousel-cell:hover{
  transform: scale(1.02);
}

.title {
  text-transform: capitalize;
}

@media(max-width:700px) {
  .carousel-cell {
    width: 85%;
  }
}
  


</style>