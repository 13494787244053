<template>
<div>
    <form ref="form" @submit.prevent="handleSubmit">
        <div class="row">
            <div class="col-md-6">
                <input required type="text" v-model="enquiry.firstName" class="input-text" placeholder="First Name">
            </div>
            <div class="col-md-6">
                <input required type="text" v-model="enquiry.lastName" class="input-text" placeholder="Last Name">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <input type="text" v-model="enquiry.phone" class="input-text" placeholder="Phone Number">
            </div>
            <div class="col-md-12">
                <input required type="email" v-model="enquiry.email" class="input-text" placeholder="Email">
            </div>
            <div class="col-md-12">
                <textarea v-model="enquiry.body" name="" id="" cols="30" rows="6" class="input-textarea" placeholder="Message"></textarea>
            </div>
        </div>
        <div class="text-center mt-3">
            <button v-if="loading" class="form-btn" style="background:#B8B8B8;" disabled>Submiting....</button>
            <button v-else type="submit" class="form-btn">Submit</button>
        </div>
    </form> 
         
    </div>   
</template>

<script>
import axios from 'axios'

export default {
    data: () => ({
        enquiry: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            body: '',
            subject: ''
        },
        loading: false,
    }),
     
    mounted() {
        this.enquiry.subject = !!this.$route.params.id? `Enquiry for ${this.$route.params.id}` : 'New Contact'
    },
    methods: {
        handleSubmit() {
            this.loading = true
            const url = "https://stayhighadventures.firebaseio.com/Enquiries.json"
            let data = JSON.stringify({ ...this.enquiry, timestamp: Date.now() })
            axios.post(url, data)
                .then(res => console.log(res))
                .finally(() => {                    
                    this.loading = false                    
                    this.fireAlert()                    
                })
        },
        fireAlert() {
            this.$fire({
                title: "Enquiry Sent Successfully",
                text: "We will be responding to your request shortly. Thanks!",
                type: "success",
                timer: 5000
            })
            .then(() => {                
                this.$refs.form.reset()                
            });            
        }
    }
}
</script>

<style scoped>
.input-text{
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #777;
    margin-bottom: 20px;
}

.input-textarea{
    width: 100%;    
    padding: 12px 15px;
    border: 1px solid #777;
    margin-bottom: 20px;
    font-family: var(--font-family-1);
}

.form-btn{
    padding: 8px 10px;
    border: 1px solid transparent;
    width: 100%;
    background: var(--primary);
    color: white;
}

.address{
    font-size: 24px;
    color: #777;
    line-height: 40px;
}

</style>