<template>
    <div>
    <div class="main-nav" :class="{ 'navbar--hidden': !showNavbar }">
        <nav :class="[ !showNavbar? 'nav-on-scroll': 'nav' ]">            
            <router-link to="/"><img width="80" :src="!showNavbar? require('@/assets/STAY HIGH_logo.png'): require('@/assets/STAY HIGH_logo2.png')"  alt=""></router-link>   
            <ul>
                <li>                                    
                    <!-- <img @click="menuActive = true" src="@/assets/svg/menu.svg" width="30" alt=""> -->
                    <Menu @click="menuActive = true" :size="30" :fillColor="showNavbar? '#fff' : '#000'" />
                </li>            
            </ul>
        </nav>
    </div>
    <div v-show="menuActive" class="fullscreenMenu">
        <div>
            <nav class="nav" style="background:transparent;">
                <a href="/">
                <img src="@/assets/STAY HIGH_logo.png" width="80px" alt=""></a>
                <ul>
                    <li>                        
                        <!-- <img @click="menuActive = false" src="@/assets/svg/cancel.svg" width="30" alt="">                      -->
                        <WindowClose @click="menuActive = false" :size="30" />
                    </li>            
                </ul>
            </nav>
        </div>
        <div style="margin-top:40px;font-size:20px;line-height:250%;margin-left:30px;">
            <li><router-link :class="[active('/')]" to="/">Home</router-link></li>                           
            <li><router-link :class="[active('/gallery')]" to="/gallery">Gallery</router-link></li>
            <li><router-link :class="[active('/about')]" to="/about">About</router-link></li>
            <li><router-link :class="[active('/contact')]" to="/contact">Contact</router-link></li>                                                            
        </div>  
          <h3 class="text-center" style="color:var(--primary); font-size:20px;font-weight:600;margin-top:100px;">Connect with us</h3>
        <div class="social-icons-mobile d-flex justify-content-center">
                    <ul class="p-0">
                        <li>
                            <a href="https://www.facebook.com/StayHighAdventures/" target="_">
                                <img class="social-icon" src="../assets/svg/facebook.svg" width="35px" alt="">
                            </a>
                        </li>  
                        <li>
                            <a href="https://www.instagram.com/stayhigh.adventures/" target="_">
                                <img class="social-icon" src="../assets/svg/instagram.svg" width="35px" alt="">
                            </a>
                        </li>     
                        <li>
                            <div>
                                <a href="https://www.youtube.com/channel/UCbwLWw5Cw9JHcS5ca2w0IgA" target="_">
                                    <img class="social-icon" src="../assets/svg/youtube.svg" width="35px" alt="">
                                </a>
                            </div>
                        </li>                 
                    </ul>
                </div> 
    </div>       
    </div>
    
    
</template>



<script>
import Menu from 'vue-material-design-icons/Menu.vue'
import WindowClose from 'vue-material-design-icons/WindowClose.vue'

export default {
  components: {
      Menu,
      WindowClose
  },
  data(){
    return{        
      showNavbar: true,
      lastScrollPosition: 0,
      dropdownHTML: "",
      clickedEl : "",
      menuActive: false,
      dropdownMobileActive: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  computed: {
    currentRoute() {
      return this.$route.path
    }
  },
  watch: {
    currentRoute() {
      if(this.menuActive) {
        this.menuActive = false
      }
    }
  },
methods: {
  active(link) {
    return this.$route.path == link? 'active' : ''
  },  
  onScroll () {    
    // const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop  
    this.scrollPosition = window.scrollY
    if(this.scrollPosition >= 100){
      this.showNavbar = false
    } else {
      this.showNavbar = true
    }    
    // if (currentScrollPosition < 0) {
    //   return
    // }
    // this.showNavbar = currentScrollPosition < this.lastScrollPosition
    // // Set the current scroll position as the last scroll position
    // this.lastScrollPosition = currentScrollPosition
  },  
}

}
</script>


<style scoped>

  img {
    transition: all 0.2s ease-in-out;
  }

    /* .dropdownItem {
      position: absolute;
      z-index: 999;
    } */

.social-icons-mobile ul li{
  margin-right: 30px;
}

    li{
      list-style: none;
    }
    
    .nav {
      padding: 25px 30px;              
    }

    .nav-on-scroll {
      padding: 5px 30px;
      background: white;
    }

    a{
        text-decoration: none;
        color: black;
    }

    .social-icons-mobile img{
    width: 35px;
    filter:invert(70%)
}

    .active {
      color: var(--primary);
      font-weight: 600;
    }


    nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }


    ul {        
        display: flex;
        align-items: center;
    }

    ul li {
        margin-right: 20px;
        list-style-type:none;
    }


    ul li:last-child{
        margin-right: 0px;
    }
    .main-nav{
      /* background: olivedrab; */
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 99;
      transition: 0.1s all ease-out;      
    }

    .main-nav.navbar--hidden {            
      transition: all 0.2s ease-in-out;
      box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.63);
  }    


    @media(max-width:480px) {

    .fullscreenMenu {
        position: fixed;
        height: 100vh;
        width: 100%;
        top:0;
        left:0;        
        z-index: 999;                
        background-size: contain;
        background-position: bottom;
        background-repeat: no-repeat;
        background-color: white;
        }        
    }

</style>