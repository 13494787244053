<template>
<!-- To add slides, name the slot as slide-1, slide-2, etc and pass the num of slides as a prop. -->
  <div>
    <flickity ref="flickity" :options="options">
      <slot>
      <!-- <slot 
        v-for="i in numSlides" 
        :name="`slide-${i}`" /> -->
      </slot>
    </flickity>
  </div>
</template>

<script>
import Flickity from "vue-flickity"

export default {
  props: {
    options: {
      required: false,
      type: Object,
      default: () => ({        
        contain: true,  
        freeScroll : true,
        autoPlay: true,     
      })
    },
    numSlides: {
      default: 0
    },
    goNext: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    newIndex: 0
  }),
  components: {
    Flickity
  },  
  methods: {
    next() {
      let selected = this.$refs.flickity.selectedIndex()
      if(selected == 4) {
        this.$refs.flickity.select(0)
      } else {
        this.$refs.flickity.next();
      }
    }    
  },
  mounted() {
    if(this.$mq == 'sm') {
      this.$refs.flickity.select(0)
    }

    this.$refs.flickity.on( 'change', index => {                
      this.$emit('change', index)      
      this.newIndex = index
    });    
  },
  watch: {
    goNext(val) {      
      if(val) {
        // if(this.$refs.flickity.selectedIndex() == this.$refs.flickity.slides().length-1) {
        //   console.log("ready")
        //     this.$refs.flickity.select(0)
        // } else {
          this.next()
        // }
        this.$emit("closeNext")
      } else {
        if(this.newIndex == 4) {
          
        }
      }
    }
  }
}
</script>

<style scoped>


</style>