<template>
    <div id="experience-item">        
        <div id="place-stats">
            <div class="row text-center">
                <div  class="col-md-4 stats">
                    <img src="../assets/svg/altitude.svg" width="60px" alt="" style="padding:15px;border:1px solid #ff6600;border-radius:50%;">    
                    <h3 class="mt-2">{{ placeStatLabels[0] }}</h3>                    
                        <span class="stats-val">
                            {{ placeStats[0] }} mts
                        </span>
                    
                </div>
                <div  class="col-md-4 stats">
                    <img src="../assets/svg/sun.svg" width="60px" alt="" style="padding:15px;border:1px solid #ff6600;border-radius:50%;">
                    <h3 class="mt-2">{{ placeStatLabels[1] }}</h3>
                    <span class="stats-val">
                        {{ placeStats[1] }} &#176;C
                    </span>
                </div>
                <div  class="col-md-4 stats">
                    <div v-if="isEvent" style="padding:15px;border:1px solid #ff6600;border-radius:50%; display: inline-block; width: 60px; height: 60px">
                        <Calendar :size="25" fillColor="#ff6600"/>
                    </div>
                    <img v-else src="../assets/svg/hiker.svg" width="60px" alt="" style="padding:15px;border:1px solid #ff6600;border-radius:50%;">
                    <h3 class="mt-2">{{ isEvent? placeStatLabels[3] : placeStatLabels[2] }}</h3>
                    <span class="stats-val">
                        {{ placeStats[2] }}
                    </span>
                </div>
            </div>
        </div>      

        <div class="container-fluid px-5">
            <div class="row">
                <div class="col-md-10 offset-md-2">
                    <div id="introduction">
                        <div class="row align-items-center">
                            <div class="col-md-9 about-place">
                                <p>
                                    {{ aboutPlace }}
                                </p>
                            </div>
                            <div class="d-flex flex-column col-md-3 p-5">
                                <h3>BOOK THIS TRIP</h3>                                
                                <router-link :to="`/book/${name}`">
                                    <button class="btn">ENQUIRE</button>                                    
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid px-50">
            
            <div id="itenarary" v-if="!!placeItenarary.length">
                <div class="row align-items-center">
                    <div class="col-md-2 text-center">
                        <!-- <img src="@/assets/logo.png" width="80px" alt=""> -->
                        <h3 class="rotate"> {{ isEvent? 'PACKAGES' : 'ITINERARY' }}</h3>
                    </div>
                    <div class="col-md-10">
                        <!-- <span style="text-decoration:underline;">DAY 1</span> <span class="mx-2">DAY 2</span> <span>DAY 3</span>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. <br> Quis, doloribus!
                        </p> -->
                        <div class="itenarary-wrapper">                            
                            <div v-for="(item,index) in placeItenarary" :key="item" class="d-flex align-items-center itenarary-day">
                                <div>
                                    <div class="day">
                                        <template v-if="isEvent">
                                        
                                            <!-- <span v-if="festivalPackages[index].txt === true" style="white-space:nowrap;"> {{ festivalPackages[index].txt }} {{index}}</span> -->
                                            
                                            <span  style="white-space:nowrap;">{{ festivalPackages[selectedFestivePlaceIndex][index].day }}D <span style="font-weight:bold;">&middot;</span> {{ festivalPackages[selectedFestivePlaceIndex][index].night }}N</span>
                                        
                                        
                                        </template>
                                        <template v-else>
                                            <span class="mr-1">Day</span>
                                            <span>{{ index+1 }}</span>
                                        </template>
                                    </div>
                                </div>
                                <div>
                                    {{ item }} 
                                </div> 
                            </div>                           
                        </div>
                    </div>
                </div>
            </div> 

            <div id="shots">
                <div class="row align-items-center">
                    <div class="col-md-2 text-center">
                        <div class="text-center">
                            <!-- <img src="@/assets/logo.png" width="80px" alt=""> -->
                            <h3 class="rotate">IMAGES</h3>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <div class="row p-2">
                            <div v-for="(shot,index) in shots" :key="`shot-${index}`" class="col-md-4 col-6 px-1 mb-1 gallery-image" >
                                <img width="100%" height="250px" style="object-fit:cover;" :src="shot" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>          

            <div id="package-details" class="py-50" v-if="!!placeInclusions.length">
                <div class="row align-items-center">
                    <div class="col-md-2 text-center">
                        <!-- <img src="@/assets/logo.png" width="80px" alt=""> -->
                        <h3 class="rotate">PACKAGE INCLUSIONS</h3>
                    </div>
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-3 col-6 mb-5"  v-for="(item, index) in placeInclusions" :key="`package-${index}`">
                                <div class="text-center">
                                    <div><component fill-color="var(--primary)" :is="icons[item.icon]" :size="48" /> </div>                                    
                                    <p>{{ item.title }}</p>
                                </div>

                            </div>
                        </div>
                        <!-- <ul class="inclusions" style="padding-left:0;">
                            <li class="d-flex align-items-center" v-for="item in placeInclusions" :key="item">
                                <img src="../assets/svg/checked.svg" width="30px" alt="" srcset="" class="mr-3">
                                {{ item }}
                            </li>
                            
                        </ul> -->
                    </div>
                </div>
            </div>    

            <div class="text-center" style="margin-bottom:60px;">
                <h3 @click="onNextExperience" class="next-trip">VIEW NEXT EXPERIENCE</h3>
                <div class="next-trip-subtitle">{{ nextLoc }}</div>
                <!-- <a id="getStartedBtn" href="#experiences">BACK TO ALL EXPERIENCES →</a> -->
            </div>       
        </div>
    </div>
</template>

<script>
import Home from 'vue-material-design-icons/Home.vue'
import Noodles from 'vue-material-design-icons/Noodles.vue'
import VanPassenger from 'vue-material-design-icons/VanPassenger.vue'
import AccountCash from 'vue-material-design-icons/AccountCash.vue'
import CurrencyUsd from 'vue-material-design-icons/CurrencyUsd.vue'
import TicketConfirmation from 'vue-material-design-icons/TicketConfirmation.vue'
import Sleep from 'vue-material-design-icons/Sleep.vue'
import Airbag from 'vue-material-design-icons/Airbag.vue'
import Flash from 'vue-material-design-icons/Flash.vue'
import Campfire from 'vue-material-design-icons/Campfire.vue'
import Water from 'vue-material-design-icons/Water.vue'
import BowlMix from 'vue-material-design-icons/BowlMix.vue'
import MedicalBag from 'vue-material-design-icons/MedicalBag.vue'
import PaperRoll from 'vue-material-design-icons/PaperRoll.vue'
import Ferry from 'vue-material-design-icons/Ferry.vue'
import CarMultiple from 'vue-material-design-icons/CarMultiple.vue'
import Bird from 'vue-material-design-icons/Bird.vue'
import Waves from 'vue-material-design-icons/Waves.vue'
import Lightbulb from 'vue-material-design-icons/LightbulbOn.vue'
import Bed from 'vue-material-design-icons/Bed.vue'
import Calendar from 'vue-material-design-icons/CalendarRange.vue'

export default {
    components: {
        Home,
        Noodles,
        VanPassenger,
        AccountCash,
        CurrencyUsd,
        TicketConfirmation,
        Sleep,
        Airbag,
        Flash,
        Campfire,
        Water,
        BowlMix,
        MedicalBag, PaperRoll, Ferry, CarMultiple, Bird, Waves,Lightbulb,Bed,Calendar 
    },
    data: () => ({
        icons: [
            Home,
            Noodles,
            VanPassenger,
            AccountCash,
            CurrencyUsd,
            TicketConfirmation,
            Sleep,
            Airbag,
            Flash,
            Campfire,
            Water,
            BowlMix,
            MedicalBag, PaperRoll, Ferry, CarMultiple, Bird, Waves, Lightbulb,Bed,Calendar 
        ],        
        placeStatLabels: [
            'ALTITUDE',
            'TEMPERATURE',
            'TREK LEVEL',
            'DATES'
        ],
        festivalPackages: [
            [
                {
                    day: 7,
                    night: 6,
                    
                },
                {
                    day: 5,
                    night: 4,
                    
                },
                {
                    day: 2,
                    night: 1,
                    
                }               
            ],            
            [
                {
                    day: 5,
                    night: 4,
                
                },
                {
                    day: 2,
                    night: 1,
                
                },
            ]
        ],
        selectedFestivePlaceIndex: 0
    }),    
    props: {
        placeStats: {
            default: () => [
                '900 ft',
                '30 C',
                'Average'
            ]
        },
        shots: {
            default : () => [1,2,3,4,5,6]
        },
        placeItenarary:{
        },
        placeInclusions:{
        },
        nextLoc: {
            type: String
        },
        aboutPlace:{
            type: String
        },
        name: {
            type: String
        },
        experiencesCardPos: {
            type: Number
        },
        isEvent: {
            type: Boolean,
            default: false
        },
        placeId: {
            type: Number,
            default: 0
        }
        
    },
    mounted() {
        this.$emit('ready')
        if(this.placeId == 6) {
            this.selectedFestivePlaceIndex = 0
        }

        if(this.placeId == 7) {
            this.selectedFestivePlaceIndex = 1
        }
    },
    methods: {
        onNextExperience() {
            window.scrollTo(0, this.experiencesCardPos);                  
            setTimeout(() => {
                this.$emit('next')            
            }, 700)
        }        
    }
}
</script>


<style scoped>

.next-trip {
    cursor: pointer;
    font-family: var(--font-family-2);   
    margin-bottom: 10px; 
}

.next-trip>a {
    text-decoration: none;
    color: #1b1b1b;
}

.next-trip-subtitle {
    text-transform: uppercase;
    letter-spacing: 7px;
    color: #6f6f6f;
    font-size: 18px;    
}

#experience-item{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.527),rgba(255, 255, 255, 0.527)) ,url('./../assets/svg/bg.svg') repeat;
    margin-top: 40px;
}
#introduction {
    padding-bottom: 60px;
    font-size: 18px;
    line-height: 35px;
}

#place-stats {
    padding: 60px;
}

#place-stats h3 {
    font-size: 20px !important;
    letter-spacing: 3px;
}

#itenarary {
    /* padding: 60px; */
    padding-bottom: 60px;
}

#package-details {
    padding-bottom: 60px;
    margin-top:40px;
}


.about-place p{
    font-size: 16px;
}

.gallery-image img{
    border-radius: 5px;
    overflow: hidden;
}

h3{
    font-size: 28px;
}

.stats-val{
    font-size: 18px;
    color: #2b2b2b;    
}

.rotate {
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
    font-family: var(--font-family-2);   
    letter-spacing: 4px; 
    color: #6f6f6f;    
}


#getStartedBtn {
    font-family: var(--font-family-2);
}


.inclusions li{
    list-style: none;
    padding: 10px 0px;
}


.day{
    padding: 8px 40px;
    background: #ff6600;
    border-radius: 50px;
    margin-right: 20px;
    color: white;
}

.itenarary-day:not(:last-child){
    margin-bottom: 70px;
}


.itenarary-wrapper{
    position: relative;
}

.itenarary-wrapper::before{
    position: absolute;
    content: "";    
    height: 85%;
    background: white;
    border-left: 4px dotted #6f6f6f;    
    z-index: -1;
    left: 60px;
    top: 20px;
}

#introduction .col-md-9{
    padding: 20px 10px;
}

#introduction .col-md-3{
    padding: 50px;
}

.px-50{
    padding-right: 50px;
    padding-left: 50px;
}

@media(max-width:700px){
    #place-stats{
        padding: 20px;
    }

    .stats{
        margin-bottom: 40px;
    }

    #introduction .col-md-9{
        padding: 0;
        text-align: justify;
    }
    
    #introduction .col-md-3{
        text-align: center;
        padding: 0;
    }

    .rotate {
        writing-mode: horizontal-tb;
        transform: rotate(0deg);
        margin-bottom: 50px;
    }
    .px-50{
        padding-right: 15px;
        padding-left: 15px;
    }
    .itenarary-wrapper::before{   
        width: 290px;
        height: 3px;
        left: -90px;
        top: 150px;
    }
    .day{
        text-align: center;
        padding: 2px 40px;
    }
}
</style>