<template>
    <div class="navigation" :class="[isSticky ? stickyClass : '']">
    <!-- <div class="navigation">         -->
        <nav class="navbar d-none d-sm-block">
            <div class="d-flex justify-content-between">
                <div class="social-icons">
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/StayHighAdventures/" target="_">
                                <img class="social-icon" src="../assets/svg/facebook.svg" width="25px" alt="">
                            </a>
                        </li>  
                        <li>
                            <a href="https://www.instagram.com/stayhigh.adventures/" target="_">
                                <img class="social-icon" src="../assets/svg/instagram.svg" width="25px" alt="">
                            </a>
                        </li>     
                        <li>
                            <div>
                                <a href="https://www.youtube.com/channel/UCbwLWw5Cw9JHcS5ca2w0IgA" target="_">
                                    <img class="social-icon" src="../assets/svg/youtube.svg" width="25px" alt="">
                                </a>
                            </div>
                        </li>                 
                    </ul>
                </div> 

                <div class="logo ml-5 pl-5">
                    <!-- <a href="">STAY HIGH ADVENTURES</a> -->
                    <router-link to="/"><img :src="isSticky? require('@/assets/STAY HIGH_logo.png'): require('@/assets/STAY HIGH_logo2.png')"  alt=""></router-link>
                    <!-- <img src="https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/stayhigh_hero_bg_video.mp4?alt=media&token=2c58433c-ed93-409b-9dbe-ca5335f338e1" width="150" alt=""> -->
                </div>                
                
                <div >
                    <ul class="ml-5">
                      <li><router-link to="/gallery">Gallery</router-link></li>
                      <li><router-link to="/about">About</router-link></li>
                      <li><router-link to="/contact">Contact</router-link></li>                       
                        <!-- <li>About</li> -->
                    </ul>
                </div>
            </div>
        </nav>      
    </div>
</template>



<script>
export default {
    data() {
        return {
            isSticky: false,
            scrollPosition: 0,
            stickyClass: 'is_sticky',
        }
    },


    created(){
        window.addEventListener('scroll', this.handleScroll)
    },
    destroyed(){
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods:{
        handleScroll(event){
            this.scrollPosition = window.scrollY
            if(this.scrollPosition >= 100){
                this.isSticky = true
            } else {
                this.isSticky = false
            }
        }
    }
}
</script>

<style scoped>

.navigation{
    position: fixed;
    width: 100%;
    z-index: 99;
}

nav{
    width: 100%;
}

.navbar{
    width: 100%;
    color: white;
    padding-top: 10px;
    transition: all 0.2s ease-in-out;
}

ul li{
    list-style: none;
    display: inline-block;
}


ul li:hover{
    color: #FF6600;
    cursor: pointer;
}

.logo{
    display: flex;
    align-items: center;
}
.logo img{
    width: 130px;
    transition: all 0.2s ease-in-out;
}

ul li:not(last-child){
    margin-right: 30px;
}

.social-icons img{
    width: 25px;
}

.navbar a{
    color: white;
}

a{
    text-decoration: none;
    
    font-size: 14px;
}

.is_sticky{
    position: fixed;
    background: white;
    color:black !important;
    transition: all 0.2s ease-in-out;
}

.is_sticky .navbar{
    padding-top: 0;
    box-shadow: 2px 3px 9px -2px rgba(0,0,0,0.20);
}

.is_sticky .social-icons img{
    width: 23px;
    filter:invert(70%)
}

.is_sticky .logo img{
    /* filter: invert(); */
}

/* .is_sticky img{
    width: 100px;
    
} */

.is_sticky > .navbar a{
    color: black !important;
}
</style>