<template>
    <div class="contact">
        <PageHeader title="Contact Us" subtitle="Reach out to us. We will be happy to help!" background="https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/heartland%2FDSC_1285.jpg?alt=media&token=88812de5-f096-483a-9517-2125192ab1ed"/>
        <div class="py-50 bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h2 class="my-1 mb-4">Get in touch</h2>
                        <!-- <p>Reach out to us. We will be happy to help!</p> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 px-50 pt-4" >
                        <div class="contact-details">
                            <ul>
                                <li>
                                    <span>
                                        <img class="svg-img" src="../assets/svg/at.svg"  alt="">
                                    </span>
                                    <p>Email : support@stayhighadventures.com</p>
                                </li>
                                <li>
                                    <span>
                                        <img class="svg-img" src="../assets/svg/phone.svg"  alt="">
                                    </span>
                                    <p>Mobile Phone : +91 88764 14671</p>

                                </li>
                                <li>
                                    <span>
                                        <img class="svg-img" src="../assets/svg/whatsapp.svg"  alt="">
                                    </span>
                                    <p>WhatsApp : +91 93956 99623</p>

                                </li>
                            </ul>
                            
                            <div class="address mt-3" >      
                                 <i>Visit us at:</i> <br>
                                    House No 116B, Aruna Choudhury Path <br> 
                                    Ghoramara, Guwahati-781036, <br> 
                                    Assam <br>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="px-50 py-50 form-wrapper">
                            <ContactForm />
                            <!-- <form action="">
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="text" class="input-text" placeholder="First Name">
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="input-text" placeholder="Last Name">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <input type="text" class="input-text" placeholder="Phone Number">
                                    </div>
                                    <div class="col-md-12">
                                        <input type="email" class="input-text" placeholder="Email">
                                    </div>
                                    <div class="col-md-12">
                                        <textarea name="" id="" cols="30" rows="10" class="input-textarea" placeholder=" Message"></textarea>
                                    </div>
                                </div>
                                <div class="text-center mt-3">
                                    <button class="form-btn">Submit</button>
                                </div>
                            </form> -->
                        </div>
                    </div>
                </div>
             
            </div>
        </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12 px-0">
                        <div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7164.92552438538!2d91.7750514!3d26.1164504!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5ff6cd89372f%3A0x9c54abe89836a81a!2sStay%20High%20Adventure!5e0!3m2!1sen!2sin!4v1616054545556!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            

    </div>
</template>

<script>
import PageHeader from '../components/PageHeader'
import ContactForm from '../components/ContactForm'
export default {
    components:{
        PageHeader,
        ContactForm
       
    },
    
}
</script>


<style scoped>
.bg{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.527),rgba(255, 255, 255, 0.527)) ,url('./../assets/svg/bg.svg') repeat;
}

.form-wrapper{
    background: white;
}

h2{
    color: var(--primary);
}

ul{
    margin: 0;
    padding: 0;
}

li{
    list-style: none;
    display: flex;
    align-items: center;
}
.contact-details p{
    font-size: 20px;
    color: #777;
    display: inline;
    margin-left: 10px;
}

.input-text{
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #777;
    margin-bottom: 20px;
}

.input-textarea{
    width: 100%;
}

.form-btn{
    padding: 8px 10px;
    border: 1px solid transparent;
    width: 100%;
    background: var(--primary);
    color: white;
}

.address{
    font-size: 20px;
    color: #777;
    line-height: 40px;
}

.svg-img{
    width: 40px;
}

@media(max-width:700px){

.contact h2 {
    font-size: 32px;
}

.contact-details p{
    font-size: 17px;
}

.address{
    font-size: 18px;
}

.px-50{
    padding-left: 20px;
    padding-right: 20px;
}

.form-wrapper{
    margin-top: 30px;
}

.svg-img{
    width: 28px;
}
}
</style>