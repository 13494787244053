<template>
    <div class="py-50">
        <div class="container-fluid" style="margin-top:60px;margin-bottom:20px;">
            <div class="row">
                <div class="col-md-3">
                            <div class="text-center">
                                <!-- <img src="../assets/svg/checked.svg" width="40px" alt=""> -->
                                <div><heart fill-color="var(--primary)" :size="48" /> </div>
                                <h4>Raw Experiences</h4>
                                <p>Crafting raw and ethnic experiences. Bringing the unexplored North East closer to you.</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="text-center">
                                <div><head-heart fill-color="var(--primary)" :size="48" /></div>
                                <h4>Local Expertise</h4>
                                <p>Our experiences involve local experts. Uplifting each experience and ensuring you travel like a local each time.</p>

                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="text-center">
                                <div><shield-check fill-color="var(--primary)" :size="48" /></div>
                                <h4>Superior Service</h4>
                                <p> Offering the best in camping stays and trekking at the most affordable rates. Our itineraries are created keeping this in mind.</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="text-center">
                                <div><star fill-color="var(--primary)" :size="48" /></div>
                                <h4>Festivals</h4>
                                <p>Specialised festival experience including Ziro Festival of Music, NH7 Weekender and Hornbill festival. </p>                                
                            </div>
                        </div>
                <!-- <div class="col-md-6 p-5" style="padding-top:0 !important;">
              
                    <div class="row mt-5">
                        
                        
                    </div>
                </div>
                <div class="col-md-6">
                    <div>
                        <h2 class="header-section">
                            <span class="highlight">Why travel with us?</span>
                        </h2>
                    </div>
                    <p class="introduction-content">
                        Born out of our love for nature and zeal for adventure. We specialize in Camping, Trekking, Adventure activities and Events. Stay High creates eco friendly experiences for our Guests and offers economic empowerment to indigenous communities.
                    </p>
                    <p class="introduction-content">
                        Bringing the beauty and splendour of great outdoors in the North Eastern states closer to you through our unique and eco friendly experiences.                        
                    </p>
                    <a href="/about">Read More</a>
                </div> -->
            </div>
        </div>
        
    </div>
</template>


<script>

import AnimatedFlexBox from './AnimatedFlexBox'
import Star from 'vue-material-design-icons/Star.vue'
import HeadHeart from 'vue-material-design-icons/HeadHeart.vue'
import ShieldCheck from 'vue-material-design-icons/ShieldCheck.vue'
import MapMarkerCheck from 'vue-material-design-icons/MapMarkerCheck.vue'
import ClipboardAccount from 'vue-material-design-icons/ClipboardAccount.vue'
import HumanFemaleDance from 'vue-material-design-icons/HumanFemaleDance.vue'
import Heart from 'vue-material-design-icons/Heart.vue'

export default {
    components:{
        AnimatedFlexBox,
        Star,
        HeadHeart,
        ShieldCheck,
        MapMarkerCheck,
        ClipboardAccount,
        HumanFemaleDance,
        Heart
    }
}
</script>

<style scoped>
.header-section{
    position: relative;
    font-family: var(--font-family-2);
    line-height: 65px;
}

/* .header-section::before{
    position: absolute;
    content: "";
    height: 5px;
    background-color: black;
    width: 40%;
    bottom: -10px;
} */

a{
    font-weight: bold;
    color: #1b1b1b;
}

a:hover{
    color: var(--primary);
}

.highlight{
  color: var(--primary);
}

.introduction-content {
    line-height: 35px;
    font-size: 18px;
    color: #5c5b5b;
}


h4{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0;
}

p{
    color: #5c5b5b;
    font-size: 16px;
}

@media(max-width:700px){
    .header-section{
        text-align: center;
    }
    .introduction-content{
        text-align: center;
    }
}
</style>