<template>
  <div class="about">
    <PageHeader title="About Us" subtitle="Let us take you on an adventure." background="https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/pageheader%2Fabout.jpg?alt=media&token=48507fa2-c68c-42f9-8eb5-028eef9f653c"/>
    <div class="py-80 bg">
      <div class="container">
        <div class="row">
          <div class="col-md-6 p-4">            
            <!-- <img class="px-2" src="https://images.unsplash.com/photo-1553856622-d1b352e9a211?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80" width="100%" alt=""> -->
            <img style="margin-top:-30px;max-height:600px;object-fit:cover;" class="px-2" src="https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/gallery%2FDSC_0377.jpg?alt=media&token=69f84489-6156-444d-9843-0055ef57e813" width="100%" alt="">
          </div>
          <div class="col-md-6 px-1 about-details">
            <h2>
              We craft raw and ethnic experiences. 
              Bringing the unexplored North East closer to you.
            </h2>
            <p>Born out of our love for nature and zeal for adventure. We specialize in Camping, Trekking, Adventure activities and Events. Stay High creates eco friendly experiences for our Guests and offers economic empowerment to indigenous communities.</p>
            <p>Bringing the beauty and splendour of great outdoors in the North Eastern states closer to you through our unique and eco friendly experiences.</p>
            <a class="about-btn" href="/contact">Contact Us →</a>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12 text-center">
            <h2>Our Partners</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 col-6">
            <div class="partners">
              <img src="../assets/partners/airbnb.png" width="70%" alt="">
            </div>
          </div>
          <div class="col-md-2 col-6">
            <div class="partners">
              <img src="../assets/partners/booking.png" width="100%" alt="">
            </div>
          </div>
          <div class="col-md-2 col-6">
            <div class="partners">
              <img src="../assets/partners/my-trip.png" width="100%" alt="">
            </div>
          </div>
          <div class="col-md-2 col-6">
            <div class="partners">
              <img src="../assets/partners/paytm.jpg" width="100%" alt="">
            </div>
          </div>
          <div class="col-md-2 col-6">
            <div class="partners">
              <img src="../assets/partners/planet.png" width="100%" alt="">
            </div>
          </div>
          <div class="col-md-2 col-6">
            <div class="partners">
              <img src="../assets/partners/trip.png" width="70%" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import PageHeader from '../components/PageHeader'
export default {
    components:{
        PageHeader
    }
}
</script>


<style scoped>
h2{ 
  font-size: 27.5px; 
  color: var(--primary);
}


.bg{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.527),rgba(255, 255, 255, 0.527)) ,url('./../assets/svg/bg.svg') repeat;

}
.about-details p{
  color: #777;
  line-height: 30px;
}

.about-btn{
  text-decoration: none;
  color: black;
  text-decoration: underline;
  font-weight: 600;
}

.about-btn:hover{
  color: var(--primary);
}

.partners{
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partners img{
   filter: grayscale(100%);
}

@media(max-width:700px) {  
  .about-details {
    margin: 0 20px;
  }
}
</style>