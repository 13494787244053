import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Slider from './components/Slider.vue'
import VueMq from 'vue-mq'
import VueSimpleAlert from 'vue-simple-alert'
import VueGtag from "vue-gtag";
require('@/assets/css/style.css')

// require('@/assets/css/font-imports.css')
Vue.config.productionTip = false

Vue.use(VueMq)
Vue.use(VueSimpleAlert)
Vue.component('Slider', Slider)

Vue.use(VueGtag, {
  config: { 
    id: "G-ER01V2LV0R",    
  },
}, router);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
